import React, {Component, useEffect} from "react";
import {navigate, Link,graphql} from "gatsby"
import {ChartDisplay,SearchForm} from '../components/index'
import Layout from '../components/layout'
import {GlobalDispatchContext, GlobalStateContext} from '../context/context'
import {filterPlainArray} from "../functions/functions"
import { Helmet } from 'react-helmet';
const IndexPage = () => {
	  
  const dispatch = React.useContext(GlobalDispatchContext);
  const state = React.useContext(GlobalStateContext);

  useEffect(() => { 
    // get data from GitHub api
    fetch("https://lead5050.staging.parachute.net/all.json")
      .then(response => response.json())
      .then(data => {
		dispatch({type: "UPDATE_DATA", payload: data})
      }) 
  }, [])
 
  const updateEmployer = (name) =>{
	dispatch({type: "UPDATE_CURRENT_EMPLOYER", payload: name})
	navigate("/employer?employer_id=" + name.employerID );
  }
  
const renderTopEmployers = () => {
	if (state != null){
		let filters = {};
		if (state.current_location_type != "All Regions/Nations"){
			filters.region = [state.current_location_type];
		}
		if (state.current_employer_type != "All Employers"){
			filters.sector = [state.current_employer_type];
		}

		if (state.current_size_type != "All Sizes"){
			filters.size = [state.current_size_type];
		}
		  const filtered = filterPlainArray(state.data, filters);

		return(
			filtered.slice(0, 12).map((el, i) => (
				<li key={"employer" + i}><div onClick={() => updateEmployer(el)}><span><strong>{i+1}.</strong>{el.name}</span> <span>{el.score}</span></div></li>
				)
			)			
		)
	}
}
if (state!= null){
  return (
	  <>
	<Helmet>
    <title>Lead5050 - Equity Index</title>
  </Helmet>
    <Layout pageTitle="Home Page">
		<div className="container">
			<div className="row justify-content-center align-items-center">
				<div className="col-lg-9">
					<div className="user-content text-center block-padding-medium">
						<h1>Gender pay gap</h1>
						<p>
						Look beyond the headline stats and compare over 10,000 UK workplaces’ record on gender equality.
						</p>

<p>The Lead5050 Equity Index brings together every piece of information organisations publish in their gender pay gap reports, to help you understand how they’re doing, compare it to others in their region or sector, and make informed decisions about where to work and where to spend your money.</p>

<p>It’s a score out of 100, based on the mean pay gap, median pay gap, bonus pay gap, and gender representation across the pay scales. The closer an organisation is to 100, the closer they are to gender parity.</p>
					</div>
				</div>
			</div>
		</div>

		<div className="container employer-data-container">
			<div className="row align-items-start justify-content-between">
				<div className="col-lg-6 col-12">
					<ChartDisplay/>
					
				</div>
				<div className="col-lg-5 col-12">
					<div className="top-employers common-background">
						<header>
							<h4>Top employers in this segment</h4>
						</header>
						<div className="top-employer-content">
							<ol>
								{renderTopEmployers()}
							</ol>
						</div>
					</div>
					
				</div>
			</div>
		</div>
		<SearchForm/>
	
    </Layout>
	</>
  ) 
}
}
export default IndexPage